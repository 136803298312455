<template lang="html">
  <div class="allenamento-dettaglio">
    <div
    v-for="(dettaglio, index) in allenamento.dettagli"
class="fase"
    :key="index"
    >
    <h3>
      {{dettaglio.des_attivita}}
      <div class="dettaglio-totale">

      <msp-totale-dettaglio
      :dettaglio = "dettaglio"
      :zone-attive = "allenamento.zone_attive"
      :sport_id = "allenamento.sport_id"
      >

      </msp-totale-dettaglio>
      </div>
    </h3>
    <msp-andature-dettaglio class="mobile"
    :dettaglio = "dettaglio"
    :um-zone = "allenamento.um_zone"
    :tests = "tests"
    :zone-attive = "allenamento.zone_attive"
    :sport_id = "allenamento.sport_id"
    >

  </msp-andature-dettaglio>

    <p class="pre note-attivita" v-text="dettaglio.note_attivita"></p>
  </div>
  </div>
</template>

<script>
import mspTotaleDettaglio from "./MspTotaleDettaglio.vue";
import mspAndatureDettaglio from "./MspAndatureDettaglio.vue";

const component = {
  props: ['allenamento','tests'],
  components: {
    mspTotaleDettaglio,
    mspAndatureDettaglio,

  },
  mixins: [
  ],
  computed: {

  },
  methods: {
  }
}
export default component;
</script>

<style lang="scss">
</style>
