<template>
    <div class="zone">
        <header v-if="titolo"  class="msp-ui-panel-header">
            <h3 v-text="titolo"></h3>
        </header>
        <table v-if="!isNuoto(sport_id)" class="msp-ui-table  table-striped table-nospan table-zone table-zone-z">
            <thead>
                <tr>
                    <th>Zona</th>
                    <th>Da</th>
                    <th>A</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="zona in filteredZone" :key="zona.id">
                    <td class="table-zone-label" :style="setStyle(zona.colore,15)">{{zona.nome}}</td>
                    <td :style="setStyle(zona.colore,15)"><edit-zona :zona="zona" key_valore="da" :um_id="um_id" :um_descrizione="um_descrizione"></edit-zona></td>
                    <td :style="setStyle(zona.colore,15)"><edit-zona :zona="zona" key_valore="a" :um_id="um_id" :um_descrizione="um_descrizione"></edit-zona></td>
                    <td :style="setStyle(zona.colore,15)">{{um_descrizione}}</td></tr>
            </tbody>
        </table>

        <table v-if="isNuoto(sport_id)" class="msp-ui-table  table-striped table-nospan table-zone">
        <thead>
        <tr>
        <th>Zona</th>
        <th v-for="distanza in distanze"
        :key = "distanza.id"
        >{{distanza.name}}</th>

        </tr>
        </thead>
            <tbody>
                <tr v-for="zona in filteredZone" :key="zona.id+'-'+zona.distanza">
                    <td class="table-zone-label" :style="setStyle(zona.colore,15)">{{zona.nome}}</td>
                    <td v-for="distanza in distanze" :key = "distanza.id"
                    :style="setStyle(zona.colore,15)">
                    <edit-zona :zona="zonaByDistanza(zona,distanza.id)" key_valore="media" :um_id="um_id" :um_descrizione="um_descrizione"></edit-zona>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="msp-ui-alert" v-if="!hasZones">
            <div class="alert-nozone" >
                <p>zone</p>
            </div>
        </div>
    </div>
</template>

<script>


import _funzioni from '@src/js/msp/funzioni.js'
import EditZona from './EditZona.vue'
import _constants from '@src/js/msp/constants.js'
import distanze from "@lib/distanze.js"
import _zone from "@cached/zone.json"
const component = {
    components: {
        EditZona,
    },
    props: ['zone', 'titolo',  'sport_id', 'um_id', 'um_descrizione' ],
    data: function () {
        const data = {
            distanze: distanze,
            ZONE:_zone,
        };
        return data;
    },
    created: function () {


    },
    computed: {
        hasZones: function() {
            if (this.sport_id == _constants.ID_NUOTO) {
                return !!(this.zone && Object.getOwnPropertyNames(this.zone).length);
            } else {
                return this.zone && this.zone.length;
            }
        },
        filteredZone2: function () {
            const ids = this.ZONE.map(el=>el.id);
            if (!this.zone) return [];
            return this.zone.filter((el)=>ids.indexOf(el.id) !== -1)
            .filter((el)=>((el.id != _constants.ZONE.REC) && (+el.sport_id == +this.sport_id)))
            .sort((a,b)=>(+a.id>+b.id?1:-1));
        },

        zonaIds: function () {
            return this.ZONE
            .filter((el)=>(+el.sport_id == +this.sport_id))
            .map((el)=>+el.id)
            .sort((a,b)=>(+a>+b?1:-1));
        },

        filteredZone: function () {
            if (!(this.zone && this.zone.length)) {
                return [];
            }
            if (this.isNuoto(this.sport_id)) {
                let filtered = this.ZONE.filter((zona)=>(zona.id != _constants.ZONE.REC && this.zonaIds.indexOf(+zona.id)!==-1));
                return filtered;
            }
            let filtered = this.zone.filter((zona)=>(zona.id != _constants.ZONE.REC && this.zonaIds.indexOf(+zona.id)!==-1));
            return filtered;
        }

    },


    mounted: function () {

    },
    watch: {
    },
    methods: {
        zonaByDistanza: function (zona, distanzaId) {
            let filtered = this.zone.find((el)=>(+el.id == +zona.id && el.distanza.toString() == distanzaId.toString()));
            return filtered;
        },
        groupById: function () {
        //     codice: 
        //     colore: 
        //     id: "8"
        //     nome: 
        //     risultato_um_descrizione: 
        //     um_descrizione: 
        //     distanze: [
        //         distanza: 
        //         a: 
        //         a_string: 
        //         da: 
        //         da_string: 
        //     ]
        },

        setBackground(col, a) {
            return _funzioni.setBackground(col, a);
        },
        setBorder(col) {
            return _funzioni.setBorder(col);
        },
        setTextWhite(col) {
            return _funzioni.setTextWhite(col);
        },

        getColor(codice) {
            let zona = this.zone.find(el=>el.codice===codice);
            return zona && zona.colore;
        },
        setStyle(col, a, toWhite) {
            var style = "";
            style += _funzioni.setBackground(col, a);
            style += _funzioni.setBorder(col);
            if (toWhite) {
                style += _funzioni.setTextWhite(col);
            }
            return style;
        },

        isNuoto(sport_id) {
            return parseInt(sport_id, 10) === _constants.ID_NUOTO;
        },

        formatRisultato(val, um) {
            return _funzioni.formatRisultato(val, um);

        },
        formatRisultatoByUmId(val, um_id, um_descrizione, hasUm = true) {
            return _funzioni.formatRisultatoByUmId(val, um_id, um_descrizione, hasUm);
        },


    }
}
    export default component;
    </script>

<style lang="scss">
.zone {
    overflow-x: auto;
    .table-zone {
        td {
            height: auto;
            padding: calc(2 * var(--ui-space));
            border-bottom: 2px solid #fff;
        }
        th {
            border: none;
        }
    }
    td.table-zone-label {
        font-weight: bold;
        color: var(--col-grigio-scuro);
        // width: 4em;
        // box-sizing: content-box;
        // border-right: 10px solid var(--col-grigio);
    }
    .table-zone-z {
        td:nth-child(2),
        td:nth-child(3) {
            background-color: #fff!important;
        }
    }
}
</style>
