
<template lang="html">
  <div class="attivita-riepilogo-cp" >
<div v-for="(row, index) in riepilogo" :key="index" class="attivita-riepilogo-cp__row">
    <div class="attivita-riepilogo-cp__time">CP {{row.cpTime}}</div>
    <div class="attivita-riepilogo-cp__value">{{row.value}}</div>
    <div class="attivita-riepilogo-cp__best">{{row.value_best}}</div>
    <div class="attivita-riepilogo-cp__variazione">{{row.variazione_value}}</div>
</div>
  </div>
</template>

<script>
const component = {
  props: [
    "riepilogo"
  ],
  computed: {
  },
  data()
  {
    return {
    }
  }
}
export default component;
</script>

<style lang="scss">
.attivita-riepilogo-cp {
    --small: 0.9em;
    &__row {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0px 10px;
        padding: 5px 10px;
        margin: 5px;
        background: var(--col-grigio-chiarissimo);
        border: var(--col-grigio-chiaro) solid 1px;

    }
    &__time {
        grid-row: 1;
        grid-column: 1 / -1;
        font-size: var(--small);
    }
    &__value {
        grid-row: 2;
    }
    &__best {
        grid-row: 2;
        font-size: var(--small);
        text-align: right;
    }
    &__variazione {
        grid-row: 2;
        font-size: var(--small);
        color: var(--col-danger);
        text-align: right;
    }

}
</style>
