<template >
  <div class="msp-totale-dettaglio">
    <span class="dati-item" v-if="totale.sec">

      <span class="dati-label">
        <span class="msp-icon-time" aria-hidden="true"></span>
      </span>
      <span class="dati-value">  {{secondsToHms(totale.sec)}}</span>
    </span>


    <span class="dati-item"
    v-if="hasDistance(sport_id) && totale.mt && convertDistanceFromIdSport(totale.mt,sport_id)">

    <span class="dati-label">
      <span class="msp-icon-distance" aria-hidden="true"></span>
    </span>
    <span class="dati-value">    {{convertDistanceFromIdSport(totale.mt,sport_id)}}  {{getUnitFromSport(sport_id)}}</span>

  </span>
</div>
</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";

const component = {
  props: ['dettaglio','zone-attive', 'sport_id'],
  mixins: [
    allenamentoCalcoli,
  ],
  computed: {
    totale: function ()
    {
      const dettaglio = this.dettaglio;
      const zone_attive = this.zoneAttive;
      if (!(dettaglio.serie && dettaglio.serie.length) )
      {
        return false;
      }
      let totaliZone = {};
      const ripetute = (+dettaglio.ripetute) ? dettaglio.ripetute : 1;
      dettaglio.serie.forEach(
        (s)=>{
          if (!s.andature) {
            return;
          }
          let totale = this.MSP().sommaTotale(s.ripetute, s.andature, zone_attive, null) || false;

          if (totale) {
            var z = totale.zone;
            for(let iz in z){
              if(!totaliZone[iz]) {
                totaliZone[iz] = {
                  mt: 0,
                  sec: 0,
                };
              }
              totaliZone[iz].mt += +z[iz].mt * ripetute;
              totaliZone[iz].sec += +z[iz].sec * ripetute;
            }
          }
        }
      );

      let tot = {
        mt: 0,
        sec: 0,
      }
      for(let nomeZona in totaliZone){
        if (totaliZone[nomeZona]) {
          tot.mt += totaliZone[nomeZona].mt;
          tot.sec += totaliZone[nomeZona].sec;
        }
      }
      return tot;
    },


  },
  methods: {
  }
}
export default component;
</script>

<style lang="scss">
.msp-totale-dettaglio {
  display: flex;
  gap: 5px;
  padding: 0 5px;
  flex-wrap: wrap;
  .dati-item {
    display: flex;
    flex: 1;
    white-space: nowrap;
    padding: 0 10px;
    align-items: center;
    background: var(--col-grigio-chiarissimo);
    gap: 3px;
    border-radius: 5px;
  }
  .dati-value {
    font-weight: normal;

  }

}

</style>
