<template>
  <div class="allenamento-feedback">


    <div class="slots-grid-3 assegnati"  v-cloak >

      <div class="slot slot__header slot__text slot__label"></div>
      <div class="slot slot__header slot__text slot__assegnato">Assegnato</div>
      <div v-if="allenamento.attivita" class="slot slot__header slot__text slot__calcolato">Eseguito</div>
      <div v-else class="slot slot__header slot__text slot__completato">Completato
        <span v-if="allenamento.attivita_id"><br> Attività</span>
      </div>
      <div class="slot slot__text slot__label">

        <span class="msp-icon-time" aria-hidden="true"></span>


        <div class="slot__percentuale" v-if="allenamento.attivita">
          {{allenamento.attivita.durata && allenamento.totalone && allenamento.totalone.sec && getPercentuale(allenamento.attivita.durata, allenamento.totalone.sec, " %")}}
        </div>
        <div class="slot__percentuale" v-else >
          {{feedback.secondi && allenamento.totalone && allenamento.totalone.sec && getPercentuale(feedback.secondi, allenamento.totalone.sec, " %")}}
        </div>

      </div>
      <div class="slot slot__time slot__assegnato">
        {{allenamento.totalone && secondsToHms(allenamento.totalone.sec)}}
      </div>

      <div v-if="allenamento.attivita" class="slot slot__time slot__calcolato">
        <span>
          {{allenamento.attivita.durata && secondsToHms(allenamento.attivita.durata)}}
        </span>
      </div>


      <template v-else>

        <div class="slot slot__time slot__completato" v-if="isEditable">
          <input-select
          format="hms"
          class=" "
          @input="updateDurata"
          placeholder="hh:mm:ss"
          v-model="feedback.hms"></input-select>

        </div>

        <div class="slot slot__time slot__completato" v-else>
          <span>
            {{feedback.durata && secondsToHms(feedback.durata)}}
          </span>
        </div>
      </template>


      <template v-if="!isPalestra(allenamento.sport_id)">

        <div class="slot slot__text slot__label">
          <span>

            <span class="msp-icon-distance" aria-hidden="true"></span> {{getUnitFromSport(allenamento.sport_id)}}
          </span>

          <div class="slot__percentuale" >
            {{allenamento.totalone && allenamento.totalone.mt && getPercentuale(allenamento.attivita&&allenamento.attivita.distanza && allenamento.attivita.distanza*1000 || +feedback.metri, allenamento.totalone.mt, " %")}}
          </div>
        </div>


        <div class="slot slot__distance slot__assegnato">
          {{allenamento.totalone && convertDistanceFromIdSport(allenamento.totalone.mt,allenamento.sport_id)}}
        </div>
        <div v-if="allenamento.attivita" class="slot slot__distance slot__calcolato">
          <span>
            {{allenamento.attivita.distanza && convertDistanceFromIdSport(allenamento.attivita.distanza*1000,allenamento.sport_id)}}
          </span>
        </div>
        <template v-else>


          <div class="slot slot__distance slot__completato" v-if="isEditable">
            <span class="editable" v-if="!editDistanza" @click="editDistanza = true;">
              {{feedback.distanzaValue && convertDistanceFromIdSport(feedback.distanzaValue*1000,allenamento.sport_id)}}
            </span>
            <input  type="number" step="100" class=" " @blur="editDistanza = false" @input="updateDistanza"  v-model="feedback.distanza" />

          </div>
          <div class="slot slot__distance slot__completato" v-else >
            <span>
              {{feedback.distanzaValue && convertDistanceFromIdSport(feedback.distanzaValue*1000,allenamento.sport_id)}}
            </span>
          </div>
        </template>

      </template>


      <template v-if="!isPalestra(allenamento.sport_id)">

        <div class="slot slot__text slot__label">
          <span class="slot--label">trimp</span>

          <div class="slot__percentuale" >
            {{trimpPercentuale}}
          </div>

        </div>

        <div class="slot slot__trimp slot__assegnato">
          <span>{{formatTrimp(allenamento.trimp)}}</span>
        </div>
        <div v-if="allenamento.attivita" class="slot slot__trimp slot__calcolato">
          <span>
            {{formatTrimp(trimpAttivita)}}
          </span>


        </div>
        <template v-else>



          <div class="slot slot__trimp slot__completato">
            {{formatTrimp(feedback.trimp)}}
          </div>
        </template>

      </template>


    </div>

    <div class="dati-percentuale" v-if="feedback.percentuale">
      <span v-text="feedback.percentuale"></span>%
    </div>

<v-card class="card-feedback">

      <v-card-title>Come è andato l'allenamento?</v-card-title>
    <msp-valutazione-allenamento :readonly="!isEditable" @update="onUpdateValutazione" :calendario-id="feedback.calendario_id" :init-value="feedback.valutazione" />
    


      <v-card-title>Percezione dello sforzo <span class="ml-1 mr-1 nowrap">(RPE)</span></v-card-title>
      <rpe
      :selected="feedback.rpe"
      :readonly="!isEditable"
      @select = "updateRpe"
      ></rpe>
    
    <v-btn block @click.prevent.stop="openChatCalendario(allenamento.calendario_id)"><v-icon class="mr-2">mdi-chat-outline</v-icon> Inserisci un commento</v-btn>
</v-card>
  </div>


</template>

<script>
import api from "@mixins/api.js";
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import rpe from "./rpe.vue";
import mspValutazioneAllenamento from "./MspValutazioneAllenamento.vue";
import inputSelect from "./inputSelect.vue";
import { mapGetters } from 'vuex'
import messaggi from "@mixins/messaggi.js";

const component = {
  components: {
    rpe,
    mspValutazioneAllenamento,
    inputSelect,
  },
  mixins: [
    allenamentoCalcoli,
  ],
  props: [
    'allenamento'
  ],
  data: function ()
  {

    let data = {
      editDurata: false,
      editDistanza: false,
      allenamenti: [],
      loadingAllenamenti: false,
      feedback:  {
        distanza: 0,
        metri: 0,
        secondi: 0,
        mt: 0,
        hms: '',
        noteAttivita: '',
        note: '',
        percentuale: 0,
        rpe: 0,
        calendario_id: 0,
      },

      attivita:  {
        idAllenamentoAssociato: 0,
        mt: 0,
        hms: '',
        noteAttivita: '',
        note: '',
        percentuale: 0,
        rpe: 0,
      },
    }
    return data;
  },
  created: function ()
  {

  },
  computed: {
    ...mapGetters('atleti', [
      'atleta',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),
    isEditable: function () {
        return (this.utente && this.utente.is_atleta);
    },
    trimpPercentuale: function ()
    {
      return this.allenamento.attivita &&  this.allenamento.attivita && this.allenamento.attivita.trimp && this.allenamento.trimp && this.getPercentuale(this.allenamento.attivita.trimp, this.allenamento.trimp, " %") || "";
    },
    trimpAttivita: function ()
    {
      const trimp = this.allenamento.attivita && this.allenamento.attivita.trimp;
      if (!trimp)
      {
        return false;
      }
      return  trimp;
    },

  },
  watch: {
  },

  mounted: function ()
  {
    this.init();
  },

  methods: {
    openChatCalendario: async function (calendario_id) {
        const newChat = await messaggi.addChat('calendario', calendario_id);
        if (!newChat) return;
        const chatId = newChat.chat_id;
        this.$store.dispatch("calendario/setDialog",true);
        this.$router.push("/chats/"+chatId);
    },

    init ()
    {
      this.feedback = {
        ...this.feedback,
        calendario_id: this.allenamento.calendario_id
      };
      this.loadFeedback(this.allenamento.calendario_id)
      .then(
        ()=>{
          this.syncRpe();
        }
      );
    },


    associaAttivita: function (attivita_id) {
      this.loading = true;
      let calendario_id = this.allenamento.calendario_id
      const url =  process.env.VUE_APP_API_BASE_URL + "/api/v2/attivita.php?associa&id="+attivita_id;
      const data = {
        'calendario_id': calendario_id
      };
        return new Promise(
          (resolve, reject)=>{
            api(url, 'POST', data)
            .then(
              (response)=>{
                this.loading = false;
                resolve(response.data);
              }
            )
            .catch((error)=>{
              reject(error);
            })

          }
        );
    },
    dissociaAllenamento: function (id)
    {
      this.loading = true;
      return new Promise((resolve, reject)=>{
          const url =  process.env.VUE_APP_API_BASE_URL +'attivita.php?dissocia&id='+id;
          api(url, 'PUT', {})
          .then(
          (result)=>{
              this.loading = false;
              if (result.success) {
                  resolve(true);
              } else {

                  resolve(false);
              }
          },
          (error)=>{
              this.loading = false;
              reject(error);
          }
          )
      });
    },

    selectAllenamento: function (el) {
      this.associaAttivita(el.id);
    },

    loadFeedback: function (calendario_id) {
        return new Promise((resolve)=>{
            const url = process.env.VUE_APP_API_BASE_URL + "/api/v2/feedback.php?calendario_id="+calendario_id;
            api(url, 'GET')
            .then((result)=>{
                if (result.success) {
                    this.feedback={
                        ...this.feedback,
                        ...result.data,
                        calendario_id: calendario_id,
                        hms: this.MSP().sec2hms(result.data.secondi)
                    };

                    if (result.data.metri) {
                        this.feedback = {
                            ...this.feedback,
                            distanza: this.convertDistanceFromIdSport(result.data.metri, this.allenamento.sport_id),
                        }
                    }
                }
                resolve(result);
            })
        })
    },

    syncRpe: function () {
        if (!this.allenamento.attivita) {
            return Promise.resolve({success: false});
        }

        if (this.feedback.rpe) {
            return this.updateRpeAttivita(this.feedback.rpe)
        }
    },

    updateRpeAttivita: function (rpe) {
        const attivita_id = this.allenamento.attivita_id;
        if (!attivita_id) {
            return Promise.resolve({success:false});
        }

        const url =  process.env.VUE_APP_API_BASE_URL + "/api/v2/attivita.php?rpe&id="+attivita_id;
        const data = {
            'rpe': rpe
        };
        return new Promise((resolve, reject)=>{
            api(url, 'POST', data)
            .then(
            (response)=>{
                this.loading = false;
                resolve(response.data);
            }
            )
            .catch((error)=>{
                reject(error);
            })
        });
    },

    updateRpe: function (rpe)
    {
      this.feedback.rpe = rpe.rpe;
      this.updateFeedback();
      if (this.allenamento.attivita)
      {
        this.updateRpeAttivita(rpe.rpe);
      }
    },
    updateDurata: function ()
    {
      this.feedback.secondi = this.MSP().hms2sec(this.feedback.hms);
      this.updateFeedback();
    },
    updateDistanza: function ()
    {
      this.feedback.metri = this.convertDistanceReverseFromIdSport(this.feedback.distanza, this.allenamento.sport_id);
      this.updateFeedback();
    },
    updateNote: function () {
      this.updateFeedback();
    },
    saveFeedback: function () {
        const url =  process.env.VUE_APP_API_BASE_URL + "/api/v2/feedback.php";
        const data = this.feedback;
        api(url, 'POST', data);
    },

    onUpdateValutazione: function ({valutazione}) {
      this.feedback.valutazione = valutazione;
        this.$emit('update-feedback', this.feedback);
    },
  updateFeedback: function () {
    if (this.feedback.hms) {
      this.feedback.durata = this.MSP().hms2sec(this.feedback.hms);
      this.feedback.secondi = this.MSP().hms2sec(this.feedback.hms);
    }
    this.MSP().debounce(()=>{
      this.saveFeedback();
      this.$emit('update-feedback', this.feedback);
    }, 300)();

  },



  addAttivita: function ()
  {
    let attivita = {
      'dataAllenamento': this.allenamento.data,
      'dataInserimento': this.allenamento.data,
      'data': this.allenamento.data,
      'device': 'manuale',
      'durata': 0,
      'distanza': 0,
      'valoreRpe': 0,
      'noteAllenamento': '',
      'gruppoSport': this.allenamento.sport_descrizione.toUpperCase(),
      'sport': this.allenamento.sport_descrizione.toUpperCase(),
      'idAllenamentoAssociato': this.allenamento.calendario_id,
      'idAllenamento': Math.floor(Date.now() / 1000)
    }
    this.attivita = Object.assign({}, attivita);
    this.$emit('add-attivita', attivita);
  }


}
}
export default component;
</script>

<style lang="scss">
.allenamento-feedback {

  position: relative;
  .dati-percentuale {
    --color: var(--col-grigio-scuro);
    color: #fff;
    padding: 0.5em 1em;
    font-size: 2em;
    font-weight: bold;
    background: var(--color);
    display: inline-block;
    margin: 0.5em auto;
  }
  .dati-commento {
    width: 100%;
  }

  .rpe-items {
    justify-content: center;
  }

  .assegnati {
    padding: 10px;
    background-color: var(--col-grigio-chiaro);
  }


  .slot {
    align-items: center;
    display: flex;
    margin: 0;
    background: #fff;
    padding: 0 5px;
    border-radius: var(--ui-radius);
    &__text {
      border: 0;
      background: none;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-align: center;
    }
    &__label {
      grid-column: 1;
      justify-content: flex-end;
    }
    &__header {
      grid-row: 1;
    }
    &__footer {
      grid-row: -1;
    }
    &__assegnato {
      grid-column: 2;
    }
    &__calcolato {
      grid-column: 3;
    }
    &__completato {
      grid-column: 3;
    }
    &__percentuale {
      font-weight: bold;
      background: var(--col-grigio-chiarissimo);
      flex: 1;
      border-radius: 5px;
    }
    &__completato,
    &__assegnato,
    &__calcolato {
      text-align:center;
      justify-content: center;
      input {
        text-align: center;
      }

    }

    &__calcolato {
      background: var(--col-ui-evidenza);
      color: #fff;
    }
    input {
      width: 6ch;
    }
    .input-select input {
      width: 100%;
    }
  }
  .slot--label {
    display: inline-block;
    margin: 0 var(--ui-space);
  }
  .msp-bar-value {
    font-size: 1em;
  }
  .msp-bar {
    width: 100px;
  }
}
[class*="slots-grid"] {
  display: grid;
  grid-gap: var(--ui-space);
  grid-template-columns: auto repeat(var(--cols),auto);
}
.slots-grid-2 {
  --cols: 2;


}
.grid-full {
  grid-column: 1 / -1;
}
.slots-grid-3 {
  --cols: 3;
}
.slots-grid-4 {
  --cols:4;
}
.slots-grid-5 {
  --cols:5;
}
.card-feedback {
  .v-card__title {
    font-weight:bold;
    font-size: 1em;
    text-align: center;
    justify-content: center;
  }
}
</style>
