import ums from '@cached/um.json';
import _funzioni from '../../msp/funzioni.js';
import rangeZone from '@cached/zona_range.json';
const lapText = "Premi tasto LAP per procedere";
export default {
    methods: {

        isNuoto: function (andatura) {
            return andatura.des_zona && andatura.des_zona.indexOf('Z') === -1;
        },

        formatAndatura: function (andatura) {
            let result = "";
            const um = ums.find(el=>+el.id===+andatura.um_id);

            if (andatura.val_andatura) {
                result += " " + _funzioni.formatRisultatoByUmId(andatura.val_andatura,andatura.um_id, um.descrizione,(um.descrizione.indexOf("mm:ss")===-1));
            }
            if (andatura.des_zona) {
                if (andatura.des_zona==="REC") {
                    result += " rec. fermo";
                } else {
                    result += " in " + andatura.des_zona;
                    if (!this.isNuoto(andatura)) {
                        result += this.zonaRangeValori(andatura, " ");
                    }
                }
            }
            if (andatura.lap) {
                result += " " + lapText;
            }
            return result;
        },

        zonaRangeValori: function (andatura, sep = " ") {
            let onlyCustom = true;
            let defaultDescrizione = this.zonaRangeDescrizione(andatura, " ");
            if (onlyCustom) {
                return sep+defaultDescrizione;
            }
            let result;
            let zona;
            let test = this.filtered_tests.find(el=>el.um_tipo_descrizione === this.umZone);
            if (test) {
                zona = this.makeZonaForRange(test,andatura.andatura_zona_id);
            }
            if (zona) {
                result = this.formatDescrizioneZona(zona, andatura);
            } else {
                result = defaultDescrizione;
            }
            return sep+result;
        },
        zonaRangeDescrizione: function (andatura, sep = " ") {
            if (!andatura.andatura_zona_range) return "";
            let zonaRange = rangeZone.find(el=>el.id == andatura.andatura_zona_range);
            if (!zonaRange) return "";
            return sep+zonaRange.nome;
        },

        makeZonaForRange: function (test, id) {
            let zr;
            let zona = test.zone.find((zona)=>+zona.id===+id);
            if (zona) {
                zr = {
                    zona:zona,
                    um_id:test.um_id,
                    tipo_id: test.um_tipo_id,
                    tipo_descrizione: test.um_tipo_descrizione,
                    um_descrizione:test.um_descrizione
                };
            } else {
                zr = null;
            }
            return zr;
        },
        hasCustomRange: function (andatura) {
            return (andatura.andatura_zona_range_da || andatura.andatura_zona_range_a);
        },

        formatDescrizioneZona: function (zona_obj, andatura) {
            let zona = zona_obj.zona;
            let result;
            let params = [zona_obj.um_id, zona_obj.um_descrizione];
            let value_da;
            let value_a;
            if (this.hasCustomRange(andatura)) {
                let norm = zona.a - zona.da;
                value_da = zona.da + norm * andatura.andatura_zona_range_da/100;
                value_a = zona.da + norm * andatura.andatura_zona_range_a/100;
            } else {
                value_da = zona.da;
                value_a = zona.a;
            }
            let valueDaString = _funzioni.formatRisultatoByUmId(value_da, ...params,false);
            let valueAString = _funzioni.formatRisultatoByUmId(value_a, ...params, true);
            result = `${valueDaString} - ${valueAString}`;
            return result;
        },
    }
}
